import {
  DocumentUpload,
  Heading,
  StackLoader,
} from "@simplecitizen/gdl-react-ui-components";
import { useCallback, useEffect, useLayoutEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "src/hooks/redux-hooks";
import { uploadNonIdentityDocument } from "src/services/apiService";
import {
  addIdentityDoc,
  setDocCaptureData,
} from "src/store/slices/identityDocCapture";
import axios, { AxiosProgressEvent } from "axios";
import { useLocation, useParams } from "react-router-dom";
import { addDoc } from "src/store/slices/uploadedNonIdDocsSlice";
import { CameraFacingMode } from "@regulaforensics/vp-frontend-document-components";
import { v4 as uuidv4 } from 'uuid';
// import useSWR from "swr";
import { base64ToFile, capitalizeEachWord } from "src/utils/utils";
import { useSelector } from "react-redux";
import useSWR from "swr";


const SWR_RETRY_COUNT = 5;

type ErrorObject = {
  [key: string]: {
    en: string;
  };
};

export type FieldError = {
  fieldName: string;
  error: string;
};

interface DocumentUploaderCardProps {
  removeDocument: (
    document: DocumentCollector,
    docToRemove: "nonIdentityDocuments" | "identityDocuments"
  ) => void;
  document: DocumentCollector;
}

interface UploadedImage {
  format: string;
  key: string;
  source: string;
}

interface ApiErrorResponse {
  title: string;
  status: number;
  document: null | string;
  errors: {
    [key: string]: {
      [lang: string]: string;
    };
  };
  warnings: {};
}

const DocumentUploaderCard = ({
  removeDocument,
  document,
}: DocumentUploaderCardProps) => {
  const location = useLocation();

  const dispatch = useAppDispatch();
  const { checkId } = useParams<{ checkId: string }>();
  const [serverErrors, setServerErrors] = useState<string[] | null>([]);
  const [submitting, setIsSubmitting] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);
  const [mobileUrl, setMobileUrl] = useState<string | null>(null);
  const [sessionId, setSessionId] = useState<string | null>(null);
  /////// GENERATE SESSION ID
  useEffect(() => {
    setSessionId(uuidv4());
    dispatch(setDocCaptureData({ selectedIDDocument: document }));
  }, [dispatch, document]);

  const client = useAppSelector(
    (state) => state.questionnaire_data.questionnaire?.client
  ); 

  /////// GENERATE MOBILE URL
  useEffect(() => {
    const url = `${window.location.origin}/document-upload/${checkId}/mobile-handoff/${document._id}/${sessionId}?startScreen=${encodeURIComponent(!document?.config?.newDocumentRequired)}&facialComparison=${encodeURIComponent(document?.config?.facialComparison)}&documentName=${encodeURIComponent(document.name.en!)}&color=${client && client?.branding?.primaryColor
    ? client?.branding?.primaryColor
    : "#14BDF3"}`;
    setMobileUrl(url);
  }, [location, checkId, document, sessionId,client]);

  const MAX_UPLOAD_ATTEMPTS = parseInt(
    process.env.REACT_APP_MAX_UPLOAD_ATTEMPTS || "1",
    3
  );

  const API_BASE_URL =
    process.env.REACT_APP_API_BASE_URL ||
    "https://localhost:5003/api/workright";

  /**
   * Converts a string by removing "data.", dots, and hyphens, and converting to lowercase.
   * @param {string} str - The string to convert.
   * @returns {string} - The processed string in lowercase without "data.", dots, or hyphens.
   */
  function toLowerNoSeparators(str: string) {
    return str
      .replace(/^data[.-]/, "")
      .replace(/[.-]/g, "")
      .toLowerCase();
  }

  /**
   * Converts an object of errors to an array of FieldError objects.
   * @param {ErrorObject} errors - The error object to convert.
   * @returns {FieldError[]} - Array of FieldError objects.
   */
  const convertErrorsToFieldErrors = useCallback((errors: ErrorObject): FieldError[] => {
    return Object.keys(errors).map((key) => ({
      fieldName: toLowerNoSeparators(key),
      error: errors[key].en,
    }));
  },[])

  const handleApiErrors = (errorResponse: ApiErrorResponse) => {
    const errorMessages = Object?.values(errorResponse?.errors)?.flatMap(
      (errorDetail) => Object?.values(errorDetail)
    );
    setServerErrors(errorMessages);
  };

  const [uploadedImage, setUploadedImage] = useState<UploadedImage | null>(
    null
  );

  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const [fieldErrors, setFieldErrors] = useState<FieldError[]>([]);
  const [docKey, setDocKey] = useState<any>(null);
  const handleFileChange = async (files: File[]): Promise<any> => {
    try {
      const formData = new FormData();
      formData.append("file", files[0]);
      formData.append("key", "front");

      const config = {
        onUploadProgress: (progressEvent: AxiosProgressEvent) => {
          const progress = Math.round(
            (progressEvent.loaded / (progressEvent.total ?? 0)) * 100
          );
          setUploadProgress(progress);
        },
        headers: {
          accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      };

      const { data } = await axios.post(
        `${API_BASE_URL}/v1/checks/${checkId}/document-collectors/${document?._id}/images`,
        formData,
        config
      );

      setUploadedImage(data);
      return Promise.resolve(data);
      // setFileUploadError(null);
    } catch (error) {
      return Promise.reject("An error occurred while uploading the file");
      // console.error("An error occurred while uploading the file", error);
    } finally {
    }
  };

  const [checkIfDocsUploaded, setCheckIfDocsUploaded] = useState(false);
  const [startFetching, setStartFetching] = useState(false);
  const [faceMisMatch,setFaceMisMatch] = useState(false)
  const [mobileUploadStatus, setMobileUploadStatus] = useState<
    "success" | "error" | "loading"
  >("loading");
  const [isMobile, setIsMobile] = useState(false);
  const selectedIDDocument = useSelector(
    (state: any) => state?.identity_doc_capture?.selectedIDDocument
  );
  const [DocServiceObjectNull, setDocServiceObjectNull] = useState(false);
  const [UKJurisdiction, setUKJurisdiction] = useState<boolean>(false);
  const [forceSubmit, setForceSumit] = useState(false);

  useLayoutEffect(() => {
    // Check if device is mobile by screen width (example threshold: 768px)
    const isMobileDevice = window.innerWidth <= 1023;

    setIsMobile(isMobileDevice);
  }, []);

  // Delay starting the fetch by `x` milliseconds after `checkIfDocsUploaded` is true
  useEffect(() => {
    if (checkIfDocsUploaded && !isMobile) {
      const delay = setTimeout(() => {
        setStartFetching(true);
      }, 3 * 1000); // x is the number of seconds you want to delay

      // Clean up the timeout if `canFetchData` becomes false
      return () => clearTimeout(delay);
    } else {
      setStartFetching(false);
    }
  }, [checkIfDocsUploaded, isMobile]);

  const fetcher = async (url: string) => {
    const response = await fetch(url);
    return response.json();
  };

  useSWR(
    startFetching
      ? `${API_BASE_URL}/v1/checks/${checkId}/document-collectors/${document._id}/mobile-handoff-sessions/${sessionId}`
      : null,
    fetcher,
    {
      refreshInterval: 2000,
      errorRetryCount: SWR_RETRY_COUNT,
      onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
        if (retryCount >= SWR_RETRY_COUNT) {
          setMobileUploadStatus("error");
          return;
        }
        if (error.status === 404) return;
        setTimeout(() => revalidate({ retryCount }), 5000);
      },
      onSuccess: (data) => {
        if (data) {
          if (data.isCompleted) {
            dispatch(addIdentityDoc({ ...document, doc_key: data.subjectId }));
            setStartFetching(false);
            setMobileUploadStatus("success");
            setSessionId(uuidv4());
          }
        }
      },
    }
  );
  const jurisdictionName = useSelector(
    (state: any) =>
      state?.questionnaire_data?.questionnaire?.jurisdiction?.name || ""
  );


  useEffect(() => {
    if (jurisdictionName === "UNITED KINGDOM") {
      setUKJurisdiction(true);
    }
  }, [jurisdictionName]);

  ///////// SUBMIT ID DOC TO THE API

  const verifyDocument = async ({ docFrontSideImage,selfieImage }: any) => {

    setForceSumit(false);
    setDocKey(null);
    setServerErrors([]);
    setFieldErrors([]);
    setFaceMisMatch(false)
    const newDocument = {
      images: [{ format: "base64", key: "front", source: docFrontSideImage },
                   {
                        format: "base64",
                        key: "selfie",
                        source: selfieImage,
                      },
      ],
    };

    try {
      const response = await axios.post(
        `${API_BASE_URL}/v1/checks/${checkId}/document-collectors/${document._id}/Validate`,
        newDocument
      );
      if (response.data._id !== null) {
        setDocServiceObjectNull(true);
      }

      setDocKey(response.data._id);
      // dispatch(
      //   addIdentityDoc({
      //     ...selectedIDDocument,
      //     doc_key: response.data?._id,
      //   })
      // );
      dispatch(
        setDocCaptureData({
          identityDocument: response.data,
          submittedIDs: {
            [selectedIDDocument._id]: response.data?._id || "",
          },
        })
      );
    } catch (error: any) {
   
      if(error.response){
        if(error?.response?.data?.errors){
          if(error?.response?.data?.errors["images.selfie"]){
         
            setFaceMisMatch(true)
          }
     
        }
      }
       // console.log({ errors: data.errors["images.selfie"] });
      const document = error?.response?.data;
      if (document?.document === null) {
        setDocServiceObjectNull(true);
      }
      if (document?.document?._id === null) {
        setDocServiceObjectNull(false);
      }

      if (document && document?.document) {
        setForceSumit(true);
      }
      if (error.response.data.errors) {
        const unprocessableDocument = document;
        const field_errors = convertErrorsToFieldErrors(
          error.response.data.errors
        );
        dispatch(
          setDocCaptureData({
            identityDocument: unprocessableDocument?.document,
            submittedIDs: {
              [selectedIDDocument?._id]:
                unprocessableDocument?.document?._id || "",
            },
          })
        );
        setFieldErrors(field_errors);
      }

      handleApiErrors(error.response.data);
      dispatch(
        setDocCaptureData({
          identityDocument: document,
          submittedIDs: {
            [selectedIDDocument?._id]: document?._id || "",
          },
        })
      );
    }
  };

  /////////FORCE SUBMIT ID DOC TO THE API

  const verifyForcedDocument = async ({ docFrontSideImage }: any) => {
    setDocKey(null);
    setServerErrors([]);
    setIsLoading(true); // Start the loader
    try {
      const base64Image = `data:image/png;base64,${docFrontSideImage}`;
      const file = base64ToFile(
        base64Image,
        "forced-document.png",
        "image/png"
      );
      const imageObject = await handleFileChange([file]);
      //
      const newDocument = {
        images: [imageObject],
        status: "created",
      };
      const response = await axios.post(
        `${API_BASE_URL}/v1/checks/${checkId}/document-collectors/${document._id}/Validate`,
        newDocument
      );
      setDocKey(response.data?._id);

      dispatch(
        setDocCaptureData({
          identityDocumentForced: response.data,
          submittedIDs: {
            [selectedIDDocument?._id]: response.data?._id,
          },
        })
      );
      return response.data?._id
    } catch (error: any) {
      handleApiErrors(error.response.data);
      const unprocessableDocument = error?.response?.data;

      setDocKey(unprocessableDocument?.document?._id);

      dispatch(
        setDocCaptureData({
          identityDocumentForced: unprocessableDocument?.document,
          submittedIDs: {
            [selectedIDDocument?._id]: unprocessableDocument?.document?._id,
          },
        })
      );
      return  unprocessableDocument?.document?._id
    } finally {
      setIsLoading(false); // End the loader after the request completes
    }
  };

  //////// SUBMIT NON ID DATA TO THE API

  const forceSubmitNonID = async ({
    documentFile
  }: any) => {
    try{
      const imageUrl = await handleFileChange([documentFile]);

      const newDocument = {
        images: [imageUrl],
        status: "created",
      };

      const response = await axios.post(
          `${API_BASE_URL}/v1/checks/${checkId}/document-collectors/${document._id}/Validate`,
          newDocument
      );

      setDocKey(response.data?._id);
      dispatch(
          setDocCaptureData({
            identityDocumentForced: response.data,
            submittedIDs: {
              [selectedIDDocument?._id]: response.data?._id,
            },
          })
      );
      return response.data?._id
    }
    catch (error: any){
      const unprocessableDocument = error?.response?.data;
      setDocKey(unprocessableDocument?.document?._id);

      dispatch(
          setDocCaptureData({
            identityDocumentForced: unprocessableDocument?.document,
            submittedIDs: {
              [selectedIDDocument?._id]: unprocessableDocument?.document?._id,
            },
          })
      );
      return unprocessableDocument?.document?._id
    }
  }

  const submitNonIdDoc = async ({
    documentNumber,
    dateOfExpiry,
    issuingStateName,
    documentFile,
  }: any) => {
    try {
      const imageUrl = await handleFileChange([documentFile]);
      setUploadedImage(imageUrl);
      if (submitting) return;
      setServerErrors([]);
      const dataToSend = [
        {
          slug: "document-number",
          name: {
            en: "Document Number",
          },
          value: documentNumber,
          source: "workright-employee-experience",
        },
        {
          slug: "date-of-expiry",
          name: {
            en: "Date of Expiry",
          },
          value:
         dateOfExpiry,
          source: "workright-employee-experience",
        },
        {
          slug: "issuing-state-name",
          name: {
            en: "Issuing State name",
          },
          value: issuingStateName,
          source: "workright-employee-experience",
        },
      ];
      const imagesToSend = [imageUrl];
      setIsSubmitting(true);
      const uploadedDocuments = await uploadNonIdentityDocument(
        document?._id!,
        checkId!,
        {
          data: dataToSend,
          images: imagesToSend,
        }
      );
      dispatch(
        addDoc({
          ...document,
          doc_key: uploadedDocuments._id,
          uploaded_image: imageUrl?.source,
        })
      );
    } catch (error: any) {
      await forceSubmitNonID({documentFile});
    } finally {
      setIsSubmitting(false);
    }
  };

  const resetMobileUploadStatus = () => {
    setMobileUploadStatus("loading");
  };

  return (
    <div>
      <div className="mb-1 font-semibold  ">
        {capitalizeEachWord(document.name.en || "")}{" "}
        <span className="">{document.config.required ? "*" : ""}</span>
      </div>

      <DocumentUpload
      faceMisMatch={faceMisMatch}
        forceSubmit={forceSubmit}
        isDocumentUploadedOnMobile={mobileUploadStatus === "success"}
        onChangeToDesktopUploader={() => {
           setCheckIfDocsUploaded(false);
        }}
        mobileUploadStatus={mobileUploadStatus}
        onOpenUploader={() => {
          if (document._subtype === "identity") {
            setMobileUploadStatus("loading");
             setCheckIfDocsUploaded(true);
          }
        }}
        onClose={() => {
          if (document._subtype === "identity") {
             setCheckIfDocsUploaded(false);
          }
        }}
        onForceRegulaDocument={async (fields, rawData) => {
        
          if (serverErrors?.length !== 0) {
         const docId =   await verifyForcedDocument({
              docFrontSideImage: fields.docFrontSideRawBase64,
            });
         
            dispatch(addIdentityDoc({ ...document, doc_key: docId }));
          }
        }}
        facialComparison={document?.config?.facialComparison}
        uploadAttempts={MAX_UPLOAD_ATTEMPTS}
        UKJurisdiction={UKJurisdiction}
        DocServiceObjectNull={DocServiceObjectNull}
         mobileHandOff={true}
        mobileUploadUrl={mobileUrl || ""}
        regulaStartScreen={!document?.config?.newDocumentRequired}
        // regulaStartScreen={true}
        regula_settings={{
          regulaLogo: false,
          changeCameraButton: true,
          cameraMode: "environment" as CameraFacingMode,
          multipageProcessing: true,
        }}
        fieldErrors={fieldErrors}
        verifyDocument={async ({ docFrontSideImage,selfieImage }, rawData) => {
          const base64Data = docFrontSideImage.split(",")[1];
          setUploadedImage(base64Data);
          await verifyDocument({ docFrontSideImage: base64Data,selfieImage });
        }}
        verifyOnProcessingDocument={true}
        onStoreRegulaDocument={async (fields, rawData) => {
           dispatch(addIdentityDoc({ ...document, doc_key: docKey }));
          if (serverErrors?.length !== 0) {
            await verifyForcedDocument({ docFrontSideImage: uploadedImage });
          }
        }}
        documentReaderProcessParams={{
          returnUncroppedImage: false,
        }}
        title={`Upload ${capitalizeEachWord(document.name.en || "")}`}
        documentName={capitalizeEachWord(document.name.en || "")}
        onRemoveDocument={() => {
          if (document._subtype === "identity") {
            resetMobileUploadStatus();
            return removeDocument(document, "identityDocuments");
          }
          removeDocument(document, "nonIdentityDocuments");
        }}
        uploadProgress={uploadProgress}
        onStoreDefaultDocument={async (docData) => {
          await submitNonIdDoc(docData);
        }}
        serverErrors={serverErrors!}
        identityType={document._subtype as "default" | "identity"}
        color={
          client && client?.branding?.primaryColor
            ? client?.branding?.primaryColor
            : "#14BDF3"
        }
      />

      {isLoading && (
        <div className="fixed top-0 left-0 w-screen h-screen bg-[rgba(0,0,0,0.7)] z-10 flex justify-center items-center">
          <div className="bg-white w-96 h-96 flex justify-center items-center rounded-lg overflow-hidden">
            {/* <div className="loader"></div> */}
            <div className="flex flex-col justify-center items-center py-48">
              <div className="grid gap-3 mb-6">
                <StackLoader />
              </div>
              <div>
                <Heading>Verifying...</Heading>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DocumentUploaderCard;
